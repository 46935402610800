import React from 'react';

import { Permissions } from 'helpers/auth';
import { Dashboard } from 'pages/Common/Dashboard';
import { NotFound } from 'pages/Common/NotFound';
import { Login } from 'pages/Authentication/Login';
import { AuthenticationLayout, GenericLayout, SimpleLayout } from 'layouts';
import { Clients } from './pages/Common/Clients';
import { ResultAnalysis } from './pages/Common/ResultAnalysis';
import { Users } from './pages/Common/UserSettings';
import { RoleAndPermissionMatrix } from './pages/Common/RoleAndPermissionMatrix';
import { Role } from './pages/Common/Roles';
import { ForgotPassword } from './pages/Authentication/ForgotPassword';
import { Register } from './pages/Authentication/Register';
import { ConfirmPassword } from './pages/Authentication/ConfirmPassword';
import { VerifyEmail } from './pages/Authentication/VerifyEmail';
import Categories from './pages/Common/Category/Categories';
import { CategoryTree } from 'pages/Common/Category';
import { MessageSuccess } from 'pages/Common/Messages/MessageSuccess';
import { MessageFail } from 'pages/Common/Messages/MessageFail';
import { Settings } from 'pages/Common/Settings';
import { SocialFacebook } from 'pages/Authentication/Social';
import { RegisterClient } from 'pages/Authentication/RegisterClient';
import { SurveyList, SurveyEditor, SelectSurveyType } from './pages/Common/Survey';
import { PrivacyPolicy } from 'pages/Common/PrivacyPolicy';

export interface SiteRoute {
  readonly path: string;
  readonly component: React.FC<any> | React.ComponentClass<any>;
  readonly layout: React.FC<any> | React.ComponentClass<any>;
  readonly permissions: Array<string>;
}

export type RouteTree = { [key: string]: SiteRoute | RouteTree };

type RouteTreeProps<T> = {
  [K in keyof T]: T[K] extends RouteTree ? RouteTreeProps<T[K]> : T[K];
};

const createRouteTree = <T extends RouteTree>(item: T): RouteTreeProps<T> => {
  return item;
};

const RouteList = createRouteTree({
  DASHBOARD: {
    path: '/dashboard',
    permissions: [],
    layout: GenericLayout,
    component: Dashboard,
  },
  SERVICES: {
    path: '/settings/memberships',
    permissions: [],
    layout: GenericLayout,
    component: Settings,
  },
  SURVEYS: {
    SURVEY_LIST: {
      path: '/surveys',
      permissions: [Permissions.SURVEYS.LIST],
      layout: GenericLayout,
      component: SurveyList,
    },
    SELECT_TYPE: {
      path: '/survey',
      permissions: [Permissions.SURVEYS.CREATE],
      layout: GenericLayout,
      component: SelectSurveyType,
    },
    SURVEY_EDITOR: {
      CREATE: {
        path: '/survey/:type/:mode',
        permissions: [Permissions.SURVEYS.GET],
        layout: GenericLayout,
        component: SurveyEditor,
      },
      EDIT: {
        path: '/survey/:type/:mode/:surveyId',
        permissions: [Permissions.SURVEYS.EDIT],
        layout: GenericLayout,
        component: SurveyEditor,
      },
    },
    RESULT_ANALYSIS: {
      path: '/result-analysis',
      permissions: [],
      layout: GenericLayout,
      component: ResultAnalysis,
    },
  },
  CLIENTS: {
    path: '/clients',
    permissions: [Permissions.CLIENTS.LIST],
    layout: GenericLayout,
    component: Clients,
  },
  USERS: {
    path: '/users',
    permissions: [Permissions.USERS.LIST],
    layout: GenericLayout,
    component: Users,
  },
  ROLE: {
    ROLES: {
      path: '/roles',
      permissions: [Permissions.ROLES.LIST],
      layout: GenericLayout,
      component: Role,
    },
    MATRIX: {
      path: '/matrix',
      permissions: [Permissions.ROLES.LIST_PERMISSION],
      layout: GenericLayout,
      component: RoleAndPermissionMatrix,
    },
  },
  SETTINGS: {
    HOME: {
      path: '/settings',
      permissions: [],
      layout: GenericLayout,
      component: Settings,
    },
    NOTIFICATIONS: {
      path: '/settings/notifications',
      permissions: [Permissions.NOTIFICATIONS.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    NEWS: {
      path: '/settings/news',
      permissions: [Permissions.NEWS.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    SYSTEM: {
      path: '/settings/system',
      permissions: [Permissions.SYSTEM.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    MEMBERSHIPS: {
      path: '/settings/memberships',
      permissions: [Permissions.MEMBERSHIPS.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    CONTACT_INFO: {
      path: '/settings/contact-info',
      permissions: [Permissions.CONTACT.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    PRIVACY_POLICY: {
      path: '/settings/privacy-policy',
      permissions: [Permissions.PRIVACY_POLICY.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    FAQ: {
      path: '/settings/faq',
      permissions: [Permissions.FAQ.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    INVOICES: {
      path: '/settings/invoices',
      permissions: [Permissions.INVOICES.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    EMAILS: {
      path: '/settings/emails',
      permissions: [Permissions.EMAILS.LIST],
      layout: GenericLayout,
      component: Settings,
    },
    TRANSLATIONS: {
      path: '/settings/translations',
      permissions: [Permissions.TRANSLATIONS.LIST],
      layout: GenericLayout,
      component: Settings,
    },
  },
  CATEGORY: {
    CATEGORIES: {
      path: '/categories',
      permissions: [Permissions.CATEGORIES.LIST],
      layout: GenericLayout,
      component: Categories,
    },
    TREE: {
      path: '/categories/:category/tree',
      permissions: [],
      layout: GenericLayout,
      component: CategoryTree,
    },
  },
  AUTHENTICATION: {
    LOGIN: {
      path: '/login',
      permissions: [],
      layout: AuthenticationLayout,
      component: Login,
    },
    FORGOT_PASSWORD: {
      path: '/forgot_password',
      permissions: [],
      layout: AuthenticationLayout,
      component: ForgotPassword,
    },
    CONFIRM_PASSWORD_RESET: {
      path: '/confirm-password-reset',
      permissions: [],
      layout: AuthenticationLayout,
      component: ConfirmPassword,
    },
    REGISTER: {
      path: '/register',
      permissions: [],
      layout: AuthenticationLayout,
      component: Register,
    },
    REGISTER_CLIENT: {
      path: '/register-client',
      permissions: [],
      layout: AuthenticationLayout,
      component: RegisterClient,
    },
    VERIFY_EMAIL: {
      path: '/verify-email',
      permissions: [],
      layout: AuthenticationLayout,
      component: VerifyEmail,
    },
    SOCIAL_FACEBOOK: {
      path: 'social/facebook/callback',
      permissions: [],
      layout: SimpleLayout,
      component: SocialFacebook,
    },
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    permissions: [],
    layout: GenericLayout,
    component: PrivacyPolicy,
  },
  COMMON: {
    SUCCESS: {
      path: '/messages/success',
      permissions: [],
      layout: SimpleLayout,
      component: MessageSuccess,
    },
    FAIL: {
      path: '/messages/fail',
      permissions: [],
      layout: SimpleLayout,
      component: MessageFail,
    },
    ERROR: {
      path: '/error',
      permissions: [],
      layout: SimpleLayout,
      component: NotFound,
    },
  },
});

export { RouteList };
